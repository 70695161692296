import React from 'react'

import { PageProps } from 'gatsby'

import BackToBlog from '@components/Blog/BackToBlog'
import BlogPostMeta from '@components/Blog/BlogPostMeta'
import BlogSidebar from '@components/Blog/BlogSidebar'
import GatsbyHead from '@components/Layout/GatsbyHead'
import Layout from '@components/Layout/Layout'
import SEO from '@components/Layout/SEO'
import SidebarSection from '@components/Layout/SidebarSection'
import Wysiwyg from '@components/Layout/Wysiwyg'

import * as styles from './BlogSingle.module.scss'

const BlogSingle = (props: PageProps) => {
	const { data } = props.pageContext

	const category = data.categories.nodes[0]

	const sidebar = <BlogSidebar />

	const main = (
		<div className={styles.article}>
			<h1 className={styles.title}>{data.title}</h1>

			<BlogPostMeta category={category} date={data.date} className={styles.meta} />

			<Wysiwyg content={data.content} className={styles.content} />

			<BackToBlog className={styles.back} />
		</div>
	)

	return (
		<Layout>
			<SidebarSection sidebar={sidebar} main={main} className={styles.sidebarSection} />
		</Layout>
	)
}

export function Head(props: any) {
	return (
		<>
			<GatsbyHead />
			<SEO data={props.pageContext.data.seo} />
		</>
	)
}

export default BlogSingle
